export const environment = {
  production: false,
  coreApiUrl: 'https://portal-uat.goatsports.app/api',
  coachPortalUrl: 'https://portal-uat.goatsports.app',
  authorityApiUrl: 'https://authority-uat.goatsports.app/api',
  authorityPortalUrl: 'https://authority-uat.goatsports.app/',
  clientAppUrl: 'goatsports://',
  sentryDSN: 'https://689b988150f2b74be90ea8595f5bcb15@o4507419812102144.ingest.de.sentry.io/4507419815313488',
  sentryEnvironment: 'uat'
};
